import "../../../../../../frappe/frappe/public/js/frappe/model/meta.js";

$.extend(frappe.meta, {
  get_table_fields: function (dt) {
    return $.map(frappe.meta.docfield_list[dt], function (d) {
      return frappe.model.table_fields.includes(d.fieldtype) &&
        d.simplified_table_type !== "Table 1-to-N"
        ? d
        : null;
    });
  },
});
