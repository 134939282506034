import "../../../../../../frappe/frappe/public/js/frappe/utils/utils.js";

Object.assign(frappe.utils, {
  get_formatted_duration(value, duration_options = null) {
    let duration = "";
    if (!duration_options) {
      duration_options = {
        hide_days: 0,
        hide_seconds: 0,
      };
    }
    if (value) {
      let total_duration = frappe.utils.seconds_to_duration(
        value,
        duration_options,
      );

      if (total_duration.days) {
        duration +=
          total_duration.days + __("d", null, "Days (Field: Duration)");
      }
      if (total_duration.hours) {
        duration += duration.length ? " " : "";
        duration +=
          total_duration.hours + __("h", null, "Hours (Field: Duration)");
      }
      if (total_duration.minutes) {
        duration += duration.length ? " " : "";
        duration +=
          total_duration.minutes + __("min", null, "Minutes (Field: Duration)");
      }
      if (total_duration.seconds) {
        duration += duration.length ? " " : "";
        duration +=
          total_duration.seconds + __("s", null, "Seconds (Field: Duration)");
      }
    }
    return duration;
  },
});
