import "../../../../../frappe/frappe/public/js/frappe/translate.js";

// method with same content as frappe original method, but normalizing key instead of using simply txt
frappe._ = function (txt, replace, context = null) {
  if (!txt) return txt;
  if (typeof txt != "string") return txt;

  let translated_text = "";

  // Nexfront: override to normalize key instead of simply using txt
  const key = txt.replace(/<[^>]*>/g, "").trim();
  if (context) {
    translated_text = frappe._messages[`${key}:${context}`];
  }

  if (!translated_text) {
    translated_text = frappe._messages[key] || txt;
  }

  if (replace && typeof replace === "object") {
    translated_text = $.format(translated_text, replace);
  }
  return translated_text;
};

// declaration with same content as frappe original declaration, but using nexfront's frappe._ instead of frappe's frappe._
window.__ = frappe._;
